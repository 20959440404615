<template>
  <div class="foodEnPageWrap en">
    <m-layout-bar>
      <div class="foodEnWrap">
        <div class="banner">
          <img :src="require('../assets/image/food_en/banner.png')" alt />
        </div>
        <div class="content">
          <div class="fixedMenus" :class="barFixed?'show':''">
            <router-link
              class="fixedMenu xiCan trans"
              :class="{'active':activeName==='xiCan'}"
              data-name="xiCan"
              tag="div"
              to="/food_en/xiCan_en"
              @click="clickMenuItem"
            >
              <img :src="require('../assets/image/food/tab_1_f.png')" alt />Western-style food
            </router-link>
            <router-link
              class="fixedMenu zhongCan trans"
              :class="{'active':activeName==='zhongCan'}"
              data-name="zhongCan"
              tag="div"
              to="/food_en/zhongCan_en"
              @click="clickMenuItem"
            >
              <img :src="require('../assets/image/food/tab_2_f.png')" alt />Chinese food
            </router-link>
            <router-link
              class="fixedMenu hongBei trans"
              :class="{'active':activeName==='hongBei'}"
              data-name="hongBei"
              tag="div"
              to="/food_en/hongBei_en"
              @click="clickMenuItem"
            >
              <img :src="require('../assets/image/food/tab_3_f.png')" alt />Baked dessert
            </router-link>
          </div>
          <div class="menusWrap">
            <div
              id="xiCanBar"
              class="menuWrap xiCan maskWrap size toShow"
              :class="{'active':activeName==='xiCan'}"
              data-name="xiCan"
              @click="clickMenuItem"
            >
              <router-link to="/food_en/xiCan_en">
                <div class="menu xiCan">
                  <span class="title">Western-style food</span>
                  <img :src="require('../assets/image/food/tab_1.png')" alt />
                </div>
              </router-link>
            </div>
            <div
              class="menuWrap zhongCan maskWrap size toShow"
              :class="{'active':activeName==='zhongCan'}"
              data-name="zhongCan"
              @click="clickMenuItem"
            >
              <router-link to="/food_en/zhongCan_en">
                <div class="menu zhongCan">
                  <span class="title">Chinese food</span>
                  <img :src="require('../assets/image/food/tab_2.png')" alt />
                </div>
              </router-link>
            </div>
            <div
              class="menuWrap hongBei maskWrap size toShow"
              :class="{'active':activeName==='hongBei'}"
              data-name="hongBei"
              @click="clickMenuItem"
            >
              <router-link to="/food_en/hongBei_en">
                <div class="menu hongBei">
                  <span class="title">Baked dessert</span>
                  <img :src="require('../assets/image/food/tab_3.png')" alt />
                </div>
              </router-link>
            </div>
          </div>
          <div class="lists">
            <router-view @orderclick="orderclickParent" />
          </div>
        </div>
      </div>

      <!-- 遮罩 -->
      <div class="iMask" v-show="showFlag">
        <div class="maskWrap">
          <img class="code" :src="require('../assets/image/food_en/code2.png')" alt />
          <img
            class="close"
            @click="closeMask"
            :src="require('../assets/image/stay/close.png')"
            alt
          />
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
import { checkIsNumber } from "@/utils/util.js";
export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      showFlag: false,
      barFixed: false,
      activeName: ""
      // bannerImgUrl: require("../assets/image/food/banner.png")
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    let path = this.$route.path;
    if (path.indexOf("xiCan") > 0) {
      this.activeName = "xiCan";
    } else if (path.indexOf("zhongCan") > 0) {
      this.activeName = "zhongCan";
    } else if (path.indexOf("hongBei") > 0) {
      this.activeName = "hongBei";
    }
  },
  methods: {
    orderclickParent() {
      this.showFlag = true;
    },
    closeMask() {
      this.showFlag = false;
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!document.querySelector("#xiCanBar")) return;
      let distOffsetTop = document.querySelector("#xiCanBar").offsetTop;

      if (parseInt(distOffsetTop).toString() === "NaN") {
        return;
      }

      if (scrollTop > distOffsetTop - 110) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }
      // console.log("window scrollTop:", scrollTop);
      // console.log("目标 distOffsetTop:", distOffsetTop);
      // console.log("fixed标识：", this.barFixed);
    },
    clickMenuItem(e) {
      if (!e.currentTarget.getAttributeNode("data-name")) return;
      this.activeName = e.currentTarget.getAttributeNode("data-name").value;
      if (!this.activeName) {
        this.activeName = "";
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.iMask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 99999;
  .maskWrap {
    position: absolute;
    width: 299px;
    height: 345px;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .code {
    width: 305px;
    height: 343px;
    margin-bottom: 10px;
    cursor: default;
  }
  .close {
    cursor: pointer;
  }
}

.foodEnWrap {
  border: 1px solid transparent;
  background: $main-white; //$main-bgYellowLight;

  .banner {
    // height:717px;
    > img {
      width: 100%;
    }
  }
  .content {
    width: 1400px;
    margin: 0 auto;
    margin-bottom: 70px;
    //  background:red;
    .fixedMenus {
      position: fixed;
      top: 120px;
      width: 1400px;
      z-index: 99998;
      display: none;
      margin: 0 auto;

      &.show {
        display: block;
      }
      > .fixedMenu {
        display: inline-block;
        width: 465px;
        height: 78px;
        line-height: 78px;
        background: $font-grayDarkD;
        font-size: 24px;
        font-weight: bold;
        color: $main-white;
        text-align: center;
        border-right: 1px solid $font-grayDark;
        > img {
          margin-right: 10px;
          vertical-align: middle;
        }
        &.xiCan {
          border-bottom: 10px solid $main-red;
          // border-bottom-left-radius: 10px;
          &:hover,
          &.active {
            background: $main-red;
          }
        }
        &.zhongCan {
          border-bottom: 10px solid $main-yellow;
          &:hover,
          &.active {
            background: $main-yellow;
          }
        }
        &.hongBei {
          border-bottom: 10px solid $main-blue;
          // border-bottom-right-radius: 10px;
          &:hover,
          &.active {
            background: $main-blue;
          }
        }
      }
      > .fixedMenu:nth-of-type(3) {
        width: 467px;
        border-right: 0px;
      }
    }
    .menusWrap {
      display: flex;
      margin-top: 90px;
      justify-content: space-between;

      .menuWrap {
        position: relative;
        text-align: center;

        .menu {
          display: table-cell;
          width: 285px;
          height: 90px;
          vertical-align: middle;
          text-align: left;
          //   line-height: 90px;
          position: relative;
          border-radius: 53px;
          span {
            text-align: center;
            color: $main-white;
            font-size: 24px;
            font-weight: bold;
            margin-left: 80px;
          }
          > img {
            position: absolute;
            left: -50px;
            bottom: 0;
          }
        }
        .menu.xiCan {
          background: $main-red;
          > .title {
            display: block;
            padding-top: 3px;
            word-wrap: break-word;
            width: 158px;
            line-height: 1.15;
            padding-left: 13px;
          }
        }
        .menu.zhongCan {
          background: $main-yellow;
        }
        .menu.hongBei {
          background: $main-blue;
        }
      }

      .menuWrap.xiCan {
        margin-left: 45px;
      }
    }
  }
}
</style>
